import React from 'react';
import { colors } from '~/styles/variables';

/// /////////
// Logos
/// /////////

export const LogoHG = () => (
  <svg width={197.563} height={34.715} viewBox="0 0 197.563 34.715">
    <defs>
      <clipPath id="LogoClip">
        <path fill="none" d="M0 0h197.563v34.715H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#LogoClip)">
      <path
        style={{ fill: '#f68b51' }}
        d="M79.827 23.417c.245 1.5 1.609 2.064 3.113 2.064 1.469 0 2.642-.631 2.677-1.4.053-1.162-.859-1.852-2.153-2.1-.892-.172-3.568-.7-3.568-.7-3.253-.6-5.107-1.536-5.107-4.3 0-3.6 3.113-5.946 7.555-5.946 4.372 0 7.275 1.994 7.975 5.037l-5.107 1.014a2.725 2.725 0 00-2.938-2.064c-1.5 0-2.257.632-2.4 1.364-.092.481.14.882 1.733 1.224 1.03.222 4.128.84 4.128.84 3.323.7 4.862 2.693 4.862 5.282 0 3.883-3.358 5.911-7.975 5.911-4.127 0-7.66-1.5-8.254-5.107zM130.843 15.432a17.068 17.068 0 01.14 2.143c0 7.132-5.094 12.262-12.191 12.262a12.3 12.3 0 11-.105-24.593c5.235 0 9.838 2.459 11.489 7.062l-5.587 1.616a6.062 6.062 0 00-5.9-3.373 6.74 6.74 0 00-6.746 6.991 6.828 6.828 0 006.922 7.027 6.173 6.173 0 006.218-4.216h-7.2v-4.919zM53.157 28.91h-5.762V19.6h-9.908v9.31h-5.8V5.37h5.8v9.165h9.908v-9.17h5.762zM74.025 20.09c0 5.586-4.427 9.31-9.662 9.31-5.34 0-9.767-3.724-9.767-9.31 0-5.551 4.427-9.275 9.767-9.275 5.235.001 9.662 3.725 9.662 9.275zm-5.411 0a4.311 4.311 0 10-4.251 4.567 4.388 4.388 0 004.25-4.567z"
      />
      <path
        style={{ fill: '#f68b51' }}
        d="M106.292 28.135a9.58 9.58 0 01-4.814 1.3c-4.392 0-7.027-2.389-7.027-7.027v-6.673h-3.2v-4.462h3.2V6.581l5.621-1.211v5.9h5.2v4.465h-5.2v6.113c0 1.827.878 2.565 2.354 2.565a5.74 5.74 0 002.565-.7zM148.766 17.737V28.91h-5.411v-1.3a7.793 7.793 0 01-5.27 1.827c-3.935 0-6.57-2.284-6.57-5.762 0-3.443 2.635-5.692 7.343-5.692h4.5v-.352a2.351 2.351 0 00-2.494-2.459c-1.687 0-2.671.667-2.952 2.213l-5.308-1.091c.773-3.408 3.865-5.551 8.081-5.551 4.954.003 8.081 2.497 8.081 6.994zm-5.411 4.216v-.387h-3.865c-1.405 0-2.283.738-2.283 1.827 0 1.124.983 1.827 2.459 1.827a3.455 3.455 0 003.689-3.266zM164.644 28.135a9.579 9.579 0 01-4.813 1.3c-4.392 0-7.026-2.389-7.026-7.027v-6.673h-3.2v-4.462h3.2V6.581l5.621-1.211v5.9h5.2v4.465h-5.2v6.113c0 1.827.878 2.565 2.354 2.565a5.742 5.742 0 002.565-.7z"
      />
      <path
        style={{ fill: '#f68b51' }}
        d="M183.194 20.09c0 5.586-4.427 9.31-9.662 9.31-5.34 0-9.767-3.724-9.767-9.31 0-5.551 4.427-9.275 9.767-9.275 5.235.001 9.662 3.725 9.662 9.275zm-5.411 0a4.311 4.311 0 10-4.251 4.567 4.388 4.388 0 004.251-4.567zM197.558 11.166l-.246 5.621h-1.014c-3.935 0-6.078 2.038-6.078 6.606v5.516h-5.622V12.483l5.621-1.211v3.373a6.812 6.812 0 016.078-3.619 4.819 4.819 0 011.261.14z"
      />
      <g>
        <path
          style={{ fill: '#4580c2', fillRule: 'evenodd' }}
          d="M18.973 25.509c-.987.955-1.974 2.259-2.962 3.214.474 2.491-.453 3.588.664 4.481.4.322 2.857 1.007 4.232.213.031-1.293-.677-1.677-1.431-2.162-.762-1.639-.489-4.13-.5-5.745"
        />
        <path
          style={{ fill: '#2e578c', fillRule: 'evenodd' }}
          d="M19.539 33.777a2.249 2.249 0 00-1.391-1.638 1.82 1.82 0 01.867 1.641c.175.008.351.008.524-.003zM20.718 33.513a2.245 2.245 0 00-1.384-1.611 1.793 1.793 0 01.85 1.788 2.759 2.759 0 00.534-.177z"
        />
        <path
          style={{ fill: '#4580c2', fillRule: 'evenodd' }}
          d="M17.819 12.612c-.508.74-.736 2.3 3.32 4.577a34.886 34.886 0 01-2.243 3.482 2.454 2.454 0 00-.341 2.693 6.434 6.434 0 00.933 1.041 3.934 3.934 0 001.92-.072 2.6 2.6 0 00.386-2.881s3.419-3.581 3.2-4.658-5.56-6.534-7.175-4.182z"
        />
        <path
          style={{ fill: '#2e578c', fillRule: 'evenodd' }}
          d="M21.794 21.452s-1.385 1.142-1.734 1.34a4.059 4.059 0 00.806-.348s.615.751.164 1.386a1.1 1.1 0 00.273-1.564 6.522 6.522 0 00.508-.74z"
        />
        <path
          style={{ fill: '#4580c2', fillRule: 'evenodd' }}
          d="M7.526 20.794a1.476 1.476 0 01-.236.745c-.233.371-.368.327.214.645a4.382 4.382 0 001.386.43c.821.134.629.042.267-.725a6.418 6.418 0 00-.56-.987c-.794-1.112-1.039-1.037-1.072-.108M4.616 20.406c-.3.556.159.643.749.849a3.016 3.016 0 00.677.151c.664.072.491-.024.2-.561a9.861 9.861 0 00-.587-.955c-1.159-1.667-.605-.3-1.041.516M2.106 20.156a3.118 3.118 0 01-.014.383c-.044.462-.1.384.478.435a3.332 3.332 0 00.764-.008c.73-.109.464-.364.083-.789a3.23 3.23 0 00-.431-.4c-1.443-1.111-.9-.33-.88.379"
        />
        <path
          style={{ fill: '#4580c2', stroke: 'transparent' }}
          d="M10.221 28.278a13.154 13.154 0 01-2.582-2.215c-.552-.644-1.242-1.058-1.839-1.662a8.606 8.606 0 00-2.655-1.923 2.591 2.591 0 00-2.279.38c-.266.122-.629.357-.924.122a.3.3 0 01-.1-.37 2.71 2.71 0 01.681-.968 5.434 5.434 0 016.086-.464 6.08 6.08 0 002.354.885 2.244 2.244 0 001.653-.47 2.176 2.176 0 00.678-1.264 3.894 3.894 0 00-.141-2.031c-.312-.938-.874-1.79-1.236-2.716q-.222-.566-.405-1.15c-.133-.077-.2-.03-.421-.384a2.928 2.928 0 01-.066-1.67q-.054-.345-.088-.69c-.585-.4-1.868-.252-2.013-.96S8.715 8.591 9.186 8.25l.049-.174c-.485-.8-1.53-.745-1.566-1.393-.036-.674 4.1-2.8 4.1-2.8a19.782 19.782 0 00-.734 2.062 33.321 33.321 0 004.84 3.2 11 11 0 013 2.643c1 1.344.427 2.928.656 4.577.078.561.116 1.128.139 1.694a21.735 21.735 0 01-.09 3.09c-.252 2.458-.38 4.458-2.111 6.3a5.566 5.566 0 01-3.992 2.064 5.513 5.513 0 01-3.256-1.235z"
        />
        <g>
          <path
            style={{ fill: '#fbce3b', fillRule: 'evenodd' }}
            d="M3.489 22.622a15.953 15.953 0 014.3 3.948c1.858 2.206 6.5 5.458 10.334.348 3.609-4.812 1.91-11.558 1.7-12.326l-.024-.078c-.122-.156-3.34-4.263-6.288-4.868-.786.683-.939 1.885.108 3.958 1.761 3.487 3.169 8.572.038 11.7a4.245 4.245 0 01-1.667 1.052c-2.226.734-3.4-.616-4.8-1.918a6.727 6.727 0 00-3.701-1.816z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M18.548 26.296a7.43 7.43 0 01-3.719-1.521 5.15 5.15 0 003.288 2.144c.152-.204.295-.412.431-.623zM12.285 26.605a8.63 8.63 0 002.244 2.912 4.866 4.866 0 00.884-.3 7.168 7.168 0 01-3.128-2.612zM7.553 26.302l.229.269.106.124a1.542 1.542 0 01.1-.98 5.189 5.189 0 00-.435.587zM9.099 27.861q.241.2.5.384a2.339 2.339 0 01.018-1.465 5.334 5.334 0 00-.518 1.081z"
          />
          <path
            style={{ fill: '#edb248', fillRule: 'evenodd' }}
            d="M6.067 24.686q.179.181.357.368a3.138 3.138 0 01.083-.772 3.768 3.768 0 00-.44.404z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M15.826 22.102a7.486 7.486 0 014.323-.005q.055-.3.1-.6a5.329 5.329 0 00-4.423.605zM20.332 17.676a23.936 23.936 0 00-.071-.7 6.626 6.626 0 00-4.546 1.385 7.326 7.326 0 014.617-.685zM19.146 13.743a21.686 21.686 0 00-.391-.433c-3.189 0-4.05 1.481-4.05 1.481a7.052 7.052 0 014.441-1.048z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M20.288 17.22a18.889 18.889 0 00-.352-2.142 5.82 5.82 0 01-.228-.142c-1.019-.664-5.5-3.382-6.811-4.161a2.637 2.637 0 00-.014.6c1.393 1.915 4.483 5.179 7.405 5.845z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M10.129 25.009a2.029 2.029 0 011.848-.887c2.051.268 1.4 2.694.91 4.122a11.64 11.64 0 00-.581 3.185c0 1.41.539 1.835-.118 2.385a5.574 5.574 0 01-3.58 1c-1.138-.258-1.169-2.6.645-3.2a7.152 7.152 0 00.442-3.378c-.026-.79-.1-2.448.433-3.228"
          />
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M9.691 34.791a5.78 5.78 0 00.479-.089 2.089 2.089 0 01.566-1.9 2.253 2.253 0 00-1.045 1.989zM8.59 34.808a2.391 2.391 0 01.55-2.112 2.168 2.168 0 00-1.031 1.815.925.925 0 00.481.297z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M9.646 22.046s-.237 1.357.683 1.812a1.852 1.852 0 002.213-.178c.66-.792 1.639-2.428-1.011-4.022-1.637-.984-1.721 2.36-1.721 2.36zM9.875 15.539c.346.749.657 1.328.672 1.38 0 0 2.14-2.65 1.741-3.089-.444-.485-2.082 1.122-2.413 1.709z"
          />
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M11.954 11.659c2.294 2.213-2.472 5.15-2.472 5.15a8.4 8.4 0 011.666 2.267c.492.82.683.683 1.284 1.366s-1.112 1.7-1.112 1.7-5.735-3.1-5.717-5.084a14.856 14.856 0 012.677-3.825c.676-.691 2.577-2.632 3.674-1.574z"
          />
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M9.728 21.034s-.428 2.112.833 2.727a2.929 2.929 0 001.542-.305s1.294-1.985.329-3.014-2.704.592-2.704.592z"
          />
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M9.7 21.185s1.659.889 1.947.971a1.553 1.553 0 01-.848-.127s-.427.808.068 1.314c0 0-.762-.136-.489-1.406l-.692-.715z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M11.188 5.177S12.05.089 14.163.002c1.388-.057 2.087 1.713 2.087 1.713a4.435 4.435 0 012.4.712.933.933 0 011.05-.475c.662.225 1.375 1.7 1.113 4.125a2.105 2.105 0 011.512 1.037 1.119 1.119 0 01-.725.463c-.437.037-2.325-.65-2.837-.2a1.631 1.631 0 00-.675 1.612 5 5 0 01-3.012-1.388c-.938-1.187-1.726-3.487-3.888-2.424z"
          />
          <path
            style={{ fill: '#f1f1f2' }}
            d="M18.369 5.543s.519-1.707 1.516-2.185a18.391 18.391 0 00.269 2.468 14.942 14.942 0 00-1.785-.283zM13.859 2.043s1.284.615 2.13 3.278a18.4 18.4 0 00-2.909-.26 6.966 6.966 0 00.779-3.018z"
          />
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M14.392 3.223c.313 0 .567.35.567.781s-.254.781-.567.781-.567-.35-.567-.781.254-.781.567-.781M19.179 5.299a.534.534 0 00.446-.591.534.534 0 00-.446-.591.347.347 0 00-.137.031 1.581 1.581 0 00-.306.517v.043a.534.534 0 00.443.591z"
          />
          <path
            style={{ fill: '#fbce3b', fillRule: 'evenodd' }}
            d="M20.714 10.73a15.087 15.087 0 002.213-.178 1.927 1.927 0 001.8-2.172c-.092-1.319-1.834-1.9-3.111-.971-1.045-.232-2.206-.929-3.251.116a1.2 1.2 0 00-.316 1.451s-2.172-.351-2.923-1.457-1.721-2.586-2.786-2.691a1.807 1.807 0 00-2.049.929c-.546 1.065-.819 2.991 3.223 6.038s5.827 3.867 6.706 3.866c1.125 0 1.193-.42 2.486-.6.66-.093 1.161-1.216.437-2.186a13.1 13.1 0 00-2.429-2.145z"
          />
          <path
            style={{ fill: '#f68b51', fillRule: 'evenodd' }}
            d="M13.557 6.523s-.874-1.011-1.721-.191c-.7.679-.178 1.817-.178 1.817s.051-.982.506-.915c0 0 .382 2.445 3.96 4.89s4.576 2.226 5.354 2.022 1.666-.6 1.366-1.229a13.572 13.572 0 00-2.13-2.186 2.611 2.611 0 01-2.376.068 13.066 13.066 0 01-5.436-4.07s.072-.457.6-.2"
          />
          <path
            style={{ fill: '#c56428', fillRule: 'evenodd' }}
            d="M17.105 9.723l.077.038z"
          />
          <path
            style={{ fill: '#f26a23', fillRule: 'evenodd' }}
            d="M20.486 10.854a2.939 2.939 0 01-2.148-.053A12.085 12.085 0 0115.48 9.22a6.048 6.048 0 00-2.335.07 11.536 11.536 0 002.981 2.836c3.578 2.445 4.575 2.226 5.354 2.022a4.4 4.4 0 00.792-.282 4.218 4.218 0 00-1.786-3.012z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M19.54 8.24c.363-.117.643.373.164.587-.067.181.041.378.335.448a.576.576 0 01-.5-1.035M23.647 8.09c-.3-.1-.531.308-.135.484.055.149-.034.312-.277.369a.475.475 0 00.412-.854"
          />
          <path
            style={{ fill: '#ebeef2', fillRule: 'evenodd' }}
            d="M19.431 12.338c.583.171.992-1.114 1.1-1.511a2.547 2.547 0 01-1.466.154c.051.15.1.3.131.415.098.355.002.874.235.942zM22.926 10.552a2.894 2.894 0 01-.343.063c.058.111.112.218.152.3.152.335.141.863.381.893.592.073.791-1.209.841-1.645a3.522 3.522 0 01-1.031.389z"
          />
        </g>
      </g>
      <g>
        <path
          style={{ fill: '#4580c2', fillRule: 'evenodd' }}
          d="M19.218 25.419c-.984.952-1.968 2.251-2.951 3.2.472 2.482-.452 3.575.662 4.465.4.321 2.848 1 4.218.212.031-1.288-.674-1.671-1.426-2.155-.76-1.633-.487-4.116-.5-5.725"
        />
        <path
          style={{ fill: '#2e578c', fillRule: 'evenodd' }}
          d="M19.781 33.663a2.242 2.242 0 00-1.386-1.632 1.814 1.814 0 01.864 1.635c.174.002.349.002.522-.003zM20.955 33.395a2.237 2.237 0 00-1.379-1.6 1.787 1.787 0 01.847 1.781 2.745 2.745 0 00.532-.181z"
        />
        <path
          style={{ fill: '#4580c2', fillRule: 'evenodd' }}
          d="M18.067 12.567c-.506.737-.733 2.29 3.309 4.561a34.822 34.822 0 01-2.236 3.47 2.446 2.446 0 00-.34 2.683 6.4 6.4 0 00.93 1.037 3.921 3.921 0 001.914-.072 2.589 2.589 0 00.385-2.871s3.407-3.568 3.193-4.641-5.545-6.51-7.155-4.167z"
        />
        <path
          style={{ fill: '#2e578c', fillRule: 'evenodd' }}
          d="M22.029 21.375s-1.381 1.138-1.728 1.335a4.043 4.043 0 00.8-.347s.613.749.164 1.382a1.1 1.1 0 00.272-1.558 6.56 6.56 0 00.506-.737z"
        />
        <path
          style={{ fill: '#4580c2', fillRule: 'evenodd' }}
          d="M7.81 20.715a1.471 1.471 0 01-.235.742c-.232.37-.367.326.214.643a4.368 4.368 0 001.381.429c.818.134.627.042.266-.723a6.388 6.388 0 00-.558-.984c-.791-1.109-1.035-1.033-1.068-.108M4.911 20.332c-.3.554.158.641.746.846a3 3 0 00.675.151c.661.071.49-.024.2-.559a9.79 9.79 0 00-.585-.952c-1.155-1.661-.6-.3-1.037.514M2.409 20.084a3.1 3.1 0 01-.014.381c-.044.461-.1.383.475.434a3.316 3.316 0 00.761-.008c.728-.109.463-.363.083-.786a3.2 3.2 0 00-.43-.4c-1.438-1.108-.9-.329-.876.377"
        />
        <path
          style={{ fill: '#4580c2', stroke: 'transparent' }}
          d="M10.495 28.176a13.107 13.107 0 01-2.57-2.207c-.55-.642-1.238-1.054-1.832-1.656a8.576 8.576 0 00-2.645-1.91 2.583 2.583 0 00-2.272.379c-.265.121-.627.355-.92.122a.3.3 0 01-.1-.368 2.7 2.7 0 01.669-.966 5.416 5.416 0 016.067-.463 6.059 6.059 0 002.346.882 2.236 2.236 0 001.647-.468 2.169 2.169 0 00.676-1.26 3.88 3.88 0 00-.141-2.024c-.311-.935-.871-1.784-1.232-2.706a15.377 15.377 0 01-.4-1.146c-.133-.077-.2-.03-.42-.383a2.918 2.918 0 01-.066-1.669q-.054-.344-.088-.688c-.583-.4-1.862-.251-2.006-.957S8.99 8.564 9.464 8.221l.049-.174c-.483-.8-1.525-.742-1.56-1.388-.036-.672 4.084-2.795 4.084-2.795a19.711 19.711 0 00-.731 2.055 33.2 33.2 0 004.819 3.188 10.957 10.957 0 012.991 2.634c1 1.34.426 2.918.654 4.561.078.559.116 1.124.138 1.688a21.652 21.652 0 01-.083 3.074c-.251 2.45-.378 4.442-2.1 6.275a5.547 5.547 0 01-3.979 2.057 5.5 5.5 0 01-3.251-1.22z"
        />
        <g>
          <path
            style={{ fill: '#fbce3b', fillRule: 'evenodd' }}
            d="M3.787 22.54a15.9 15.9 0 014.281 3.934c1.851 2.2 6.48 5.439 10.3.347 3.6-4.8 1.9-11.517 1.695-12.282l-.024-.078c-.121-.156-3.329-4.248-6.266-4.85-.783.681-.936 1.878.108 3.944 1.755 3.475 3.158 8.542.038 11.654a4.231 4.231 0 01-1.662 1.048c-2.218.732-3.393-.614-4.78-1.911a6.7 6.7 0 00-3.69-1.806z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M18.793 26.203a7.4 7.4 0 01-3.706-1.516 5.132 5.132 0 003.277 2.137c.153-.204.295-.411.429-.621zM12.553 26.511a8.6 8.6 0 002.236 2.9 4.845 4.845 0 00.88-.3 7.146 7.146 0 01-3.116-2.6zM7.837 26.209c.076.089.153.178.228.268q.051.061.106.123a1.537 1.537 0 01.1-.976 5.166 5.166 0 00-.434.585zM9.378 27.763q.24.2.5.383a2.332 2.332 0 01.018-1.46 5.327 5.327 0 00-.518 1.077z"
          />
          <path
            style={{ fill: '#edb248', fillRule: 'evenodd' }}
            d="M6.356 24.6q.178.18.356.367a3.127 3.127 0 01.082-.769 3.752 3.752 0 00-.438.402z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M16.082 22.024a7.461 7.461 0 014.308-.005q.055-.3.1-.6a5.31 5.31 0 00-4.408.605zM20.572 17.613c-.02-.242-.044-.474-.071-.7a6.6 6.6 0 00-4.531 1.38 7.3 7.3 0 014.602-.68zM19.391 13.695a24.848 24.848 0 00-.389-.432c-3.178 0-4.036 1.476-4.036 1.476a7.03 7.03 0 014.425-1.044z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M20.528 17.156a18.8 18.8 0 00-.351-2.134 7.253 7.253 0 01-.227-.141c-1.016-.662-5.478-3.37-6.788-4.146a2.647 2.647 0 00-.014.6c1.389 1.908 4.469 5.161 7.38 5.821z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M10.404 24.921a2.022 2.022 0 011.842-.884c2.043.267 1.4 2.684.907 4.108a11.6 11.6 0 00-.579 3.174c0 1.405.537 1.828-.117 2.377a5.554 5.554 0 01-3.567.995c-1.135-.257-1.166-2.591.643-3.186a7.131 7.131 0 00.441-3.366 6.5 6.5 0 01.431-3.217"
          />
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M9.968 34.668a5.78 5.78 0 00.479-.089 2.079 2.079 0 01.563-1.89 2.248 2.248 0 00-1.042 1.979zM8.87 34.684a2.383 2.383 0 01.548-2.1 2.161 2.161 0 00-1.027 1.808.922.922 0 00.479.292z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M9.922 21.967s-.236 1.352.68 1.806a1.846 1.846 0 002.205-.177c.658-.789 1.633-2.42-1.007-4.008-1.631-.981-1.715 2.352-1.715 2.352zM10.151 15.48c.345.746.655 1.324.669 1.375 0 0 2.133-2.641 1.735-3.078-.442-.478-2.075 1.123-2.404 1.703z"
          />
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M12.222 11.618c2.286 2.2-2.464 5.131-2.464 5.131a8.378 8.378 0 011.66 2.259c.49.817.68.681 1.28 1.361s-1.109 1.691-1.109 1.691-5.715-3.088-5.7-5.066a14.8 14.8 0 012.668-3.811c.677-.685 2.571-2.62 3.665-1.565z"
          />
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M10.004 20.96s-.427 2.1.83 2.717a2.92 2.92 0 001.537-.3s1.29-1.978.328-3-2.695.583-2.695.583z"
          />
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M9.976 21.11s1.654.886 1.941.967a1.548 1.548 0 01-.845-.127s-.425.805.068 1.309c0 0-.76-.136-.487-1.4l-.69-.712z"
          />
        </g>
        <g>
          <path
            style={{ fill: '#4580c2', fillRule: 'evenodd' }}
            d="M11.46 5.158s.859-5.07 2.965-5.157c1.384-.057 2.08 1.707 2.08 1.707a4.42 4.42 0 012.392.71.93.93 0 011.046-.473c.66.224 1.37 1.694 1.109 4.111a2.1 2.1 0 011.507 1.034 1.115 1.115 0 01-.722.461c-.436.037-2.317-.648-2.828-.2a1.626 1.626 0 00-.673 1.607 4.981 4.981 0 01-3-1.383c-.937-1.183-1.721-3.475-3.876-2.417z"
          />
          <path
            style={{ fill: '#f1f1f2' }}
            d="M18.617 5.524s.517-1.7 1.51-2.178a18.428 18.428 0 00.268 2.459 14.9 14.9 0 00-1.778-.281zM14.125 2.035s1.279.612 2.123 3.267a18.338 18.338 0 00-2.9-.259 6.934 6.934 0 00.777-3.008z"
          />
          <path
            style={{ fill: '#2e578c', fillRule: 'evenodd' }}
            d="M14.655 3.212c.312 0 .565.348.565.778s-.253.778-.565.778-.565-.348-.565-.778.253-.778.565-.778M19.424 5.28a.532.532 0 00.445-.589.532.532 0 00-.445-.589.347.347 0 00-.137.031 1.579 1.579 0 00-.305.515v.043a.533.533 0 00.442.589z"
          />
          <path
            style={{ fill: '#fbce3b', fillRule: 'evenodd' }}
            d="M20.953 10.692a15.037 15.037 0 002.205-.177 1.921 1.921 0 001.8-2.164c-.092-1.315-1.828-1.893-3.1-.968-1.041-.231-2.2-.926-3.24.116a1.194 1.194 0 00-.316 1.446s-2.164-.349-2.913-1.452-1.715-2.577-2.776-2.681a1.8 1.8 0 00-2.042.925c-.544 1.062-.817 2.981 3.212 6.016s5.807 3.853 6.683 3.852c1.12 0 1.189-.418 2.477-.6.658-.092 1.157-1.211.436-2.178a13.057 13.057 0 00-2.426-2.135z"
          />
          <path
            style={{ fill: '#f68b51', fillRule: 'evenodd' }}
            d="M13.821 6.5s-.871-1.007-1.715-.191c-.7.676-.177 1.81-.177 1.81s.05-.979.5-.912c0 0 .381 2.436 3.947 4.873s4.56 2.219 5.336 2.015 1.661-.6 1.361-1.225a13.535 13.535 0 00-2.124-2.178 2.6 2.6 0 01-2.368.068 13.02 13.02 0 01-5.417-4.056s.072-.455.6-.2"
          />
          <path
            style={{ fill: '#c56428', fillRule: 'evenodd' }}
            d="M17.357 9.689l.077.038z"
          />
          <path
            style={{ fill: '#f26a23', fillRule: 'evenodd' }}
            d="M20.727 10.813a2.929 2.929 0 01-2.141-.053 12.03 12.03 0 01-2.849-1.575 6.026 6.026 0 00-2.327.07 11.5 11.5 0 002.971 2.826c3.566 2.436 4.56 2.219 5.336 2.015a4.387 4.387 0 00.788-.281 4.2 4.2 0 00-1.778-3.002z"
          />
          <path
            style={{ fill: '#eca93e', fillRule: 'evenodd' }}
            d="M19.784 8.211c.362-.117.641.372.164.585-.068.18.041.377.334.446a.573.573 0 01-.5-1.031M23.878 8.062c-.3-.1-.529.307-.135.483.055.148-.034.311-.276.368a.473.473 0 00.41-.851"
          />
          <path
            style={{ fill: '#ebeef2', fillRule: 'evenodd' }}
            d="M19.674 12.295c.581.171.988-1.11 1.1-1.506a2.539 2.539 0 01-1.461.153c.051.149.1.3.13.413.095.354-.001.872.231.94zM23.158 10.515a2.869 2.869 0 01-.342.063c.058.11.112.217.151.3.151.334.141.86.38.889.589.073.788-1.205.838-1.639a3.5 3.5 0 01-1.027.387z"
          />
        </g>
      </g>
    </g>
  </svg>
);

/// /////////
// Arts
/// /////////

export const ArtDesk = () => (
  <svg width={484.478} height={352.996} viewBox="0 0 484.478 352.996">
    <g transform="translate(0 -302.871)">
      <path
        style={{ fill: '#3a6fb6' }}
        d="M450.344 531.585h24.758v124.282h-24.758z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M448.888 531.585h10.68v124.282h-10.68z"
      />
      <path
        style={{ fill: '#1e4382' }}
        d="M162.942 532.557h291.284v119.912H162.942z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M344.994 540.325h100.493v29.128H344.994zM344.994 575.279h100.493v29.128H344.994zM344.994 610.233h100.493v29.128H344.994zM264.389 540.325h67.498v99.037h-67.498zM192.07 540.325h67.497v99.037H192.07z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M254.21 558.774a4.37 4.37 0 11-4.37-4.37 4.371 4.371 0 014.37 4.37zM279.94 558.774a4.37 4.37 0 11-4.37-4.37 4.371 4.371 0 014.37 4.37zM399.61 554.892a4.37 4.37 0 11-4.37-4.37 4.371 4.371 0 014.37 4.37zM399.61 590.815a4.37 4.37 0 11-4.37-4.37 4.371 4.371 0 014.37 4.37zM399.61 626.254a4.37 4.37 0 11-4.37-4.37 4.371 4.371 0 014.37 4.37z"
      />
      <rect
        style={{ fill: '#3a6fb6' }}
        width={51.46}
        height={23.303}
        rx={6.732}
        transform="translate(388.595 446.871)"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M384.226 446.871h24.517v63.112h-24.517z"
      />
      <path
        style={{ fill: '#2159a8' }}
        d="M445.153 519.207v-3.641a8.739 8.739 0 00-8.739-8.738h-49.76a8.739 8.739 0 00-8.739 8.738v3.641z"
      />
      <rect
        style={{ fill: '#2159a8' }}
        width={51.46}
        height={23.303}
        rx={6.732}
        transform="translate(367.477 446.871)"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M414.082 470.174h18.448v4.612h-18.448z"
      />
      <path
        style={{ fill: '#50c0db' }}
        d="M412.659 478.766h20.745v27.384h-20.745z"
      />
      <path
        style={{ fill: '#50c0db' }}
        d="M418.61 483.885a7.477 7.477 0 017.468 7.467v2.764a7.478 7.478 0 01-7.468 7.466h-4.98a7.476 7.476 0 01-7.468-7.468v-2.768a7.476 7.476 0 017.468-7.468h4.98m0-2.49h-4.98a9.958 9.958 0 00-9.958 9.958v2.77a9.958 9.958 0 009.958 9.958h4.98a9.959 9.959 0 009.958-9.957v-2.768a9.958 9.958 0 00-9.958-9.958z"
      />
      <path
        style={{ fill: '#2159a8' }}
        d="M363.107 446.871h24.517v63.112h-24.517z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M424.035 519.207v-3.641a8.739 8.739 0 00-8.739-8.738h-49.761a8.739 8.739 0 00-8.739 8.738v3.641zM420.515 473.694h3.641v2.549h-3.641z"
      />
      <path
        style={{ fill: '#50c0db' }}
        d="M369.54 452.212h10.923v11.651H369.54z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M394.663 464.956a1.457 1.457 0 01-1.457 1.455 1.455 1.455 0 01-1.456-1.455v-13.472a1.456 1.456 0 011.456-1.456 1.456 1.456 0 011.457 1.455zM399.761 464.956a1.457 1.457 0 01-1.457 1.455 1.455 1.455 0 01-1.456-1.455v-13.472a1.456 1.456 0 011.456-1.456 1.456 1.456 0 011.457 1.455zM255.245 486.412h20.745v27.384h-20.745z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M261.191 491.225a7.783 7.783 0 017.775 7.775v2.768a7.783 7.783 0 01-7.775 7.773h-4.978a7.782 7.782 0 01-7.775-7.773V499a7.781 7.781 0 017.775-7.772h4.978m0-2.185h-4.978a9.958 9.958 0 00-9.959 9.957v2.768a9.957 9.957 0 009.958 9.957h4.979a9.956 9.956 0 009.959-9.955V499a9.959 9.959 0 00-9.958-9.957z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M280.733 487.14h20.745v27.384h-20.745z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M286.679 492.681a7.054 7.054 0 017.046 7.044v2.768a7.055 7.055 0 01-7.046 7.045H281.7a7.053 7.053 0 01-7.045-7.045v-2.768a7.053 7.053 0 017.045-7.044h4.98m0-2.913h-4.98a9.957 9.957 0 00-9.958 9.957v2.768a9.959 9.959 0 009.958 9.958h4.98a9.955 9.955 0 009.957-9.953v-2.768a9.956 9.956 0 00-9.953-9.957h-.005z"
      />
      <path
        style={{ fill: '#50c0db' }}
        d="M211 461.437a5.831 5.831 0 015.826 5.825v17.6a5.833 5.833 0 01-5.826 5.826h-5.947a5.834 5.834 0 01-5.826-5.826v-17.6a5.832 5.832 0 015.826-5.825H211m0-2.913h-5.947a8.737 8.737 0 00-8.739 8.737v17.6a8.739 8.739 0 008.739 8.739H211a8.74 8.74 0 008.739-8.739v-17.6a8.738 8.738 0 00-8.737-8.739z"
      />
      <rect
        style={{ fill: '#3a6fb6' }}
        width={30.949}
        height={44.785}
        rx={6.732}
        transform="translate(202.811 470.783)"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M209.547 459.131h17.477v19.298h-17.477z"
      />
      <path
        style={{ fill: '#50c0db' }}
        d="M206.113 458.524a5.462 5.462 0 005.437 4.975h13.472a5.462 5.462 0 005.437-4.976z"
      />
      <path
        style={{ fill: '#1e4382' }}
        d="M187.809 514.11a5.4 5.4 0 005.394 5.155H309.47a5.4 5.4 0 005.394-5.155z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M163.433 519.748h321.045v13.592H163.433z"
      />
      <path style={{ fill: '#023577' }} d="M0 400.197h120.431v251.809H0z" />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M43.793 400.197H127v251.809H43.793z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M50.727 406.766h70.799v64.96H50.727z"
      />
      <path
        style={{ fill: '#023577' }}
        d="M50.727 468.807h70.799v2.919H50.727z"
      />
      <path style={{ fill: '#023577' }} d="M48.537 406.766h2.92v64.96h-2.92z" />
      <path
        style={{ fill: '#1e4382' }}
        d="M74.813 418.08h27.736v13.137H74.813z"
      />
      <path
        style={{ fill: '#2159a8' }}
        d="M104.013 454.573a2.191 2.191 0 01-2.191 2.191H74.814a2.191 2.191 0 01-2.191-2.191 2.191 2.191 0 012.191-2.191h27.008a2.191 2.191 0 012.191 2.191z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M50.727 478.578h70.799v64.96H50.727z"
      />
      <path
        style={{ fill: '#023577' }}
        d="M50.727 540.619h70.799v2.919H50.727z"
      />
      <path style={{ fill: '#023577' }} d="M48.537 478.578h2.92v64.96h-2.92z" />
      <path
        style={{ fill: '#1e4382' }}
        d="M74.813 489.892h27.736v13.137H74.813z"
      />
      <path
        style={{ fill: '#2159a8' }}
        d="M104.013 526.386a2.191 2.191 0 01-2.191 2.191H74.814a2.191 2.191 0 01-2.191-2.191 2.191 2.191 0 012.191-2.191h27.008a2.191 2.191 0 012.191 2.191z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M50.727 550.606h70.799v64.96H50.727z"
      />
      <path
        style={{ fill: '#023577' }}
        d="M50.727 612.647h70.799v2.919H50.727z"
      />
      <path style={{ fill: '#023577' }} d="M48.537 550.606h2.92v64.96h-2.92z" />
      <path
        style={{ fill: '#1e4382' }}
        d="M74.813 561.92h27.736v13.137H74.813z"
      />
      <path
        style={{ fill: '#2159a8' }}
        d="M104.013 598.415a2.192 2.192 0 01-2.191 2.191H74.814a2.192 2.192 0 01-2.191-2.191 2.192 2.192 0 012.191-2.192h27.008a2.192 2.192 0 012.191 2.192z"
      />
      <path style={{ fill: '#2394af' }} d="M48.172 357.5h45.983v42.7H48.172z" />
      <path
        style={{ fill: '#50c0db' }}
        d="M78.827 357.499h22.991v42.699H78.827z"
      />
      <path
        style={{ fill: '#2394af' }}
        d="M14.233 357.499h45.983v42.699H14.233z"
      />
      <path
        style={{ fill: '#50c0db' }}
        d="M44.888 357.499H67.88v42.699H44.888z"
      />
      <g transform="translate(82 -42)">
        <circle
          style={{ fill: '#023577' }}
          cx={24.038}
          cy={24.038}
          r={24.038}
          transform="translate(189 344.871)"
        />
        <circle
          style={{ fill: '#1e4382' }}
          cx={24.038}
          cy={24.038}
          r={24.038}
          transform="translate(193.452 344.871)"
        />
        <circle
          style={{ fill: '#3a6fb6' }}
          cx={20.477}
          cy={20.477}
          r={20.477}
          transform="translate(197.235 348.21)"
        />
        <path
          style={{ fill: '#023577' }}
          d="M219.155 371.612a1.555 1.555 0 01-1.33-.744l-9.013-14.722a1.558 1.558 0 112.616-1.693l.031.05.01.016 9.009 14.721a1.558 1.558 0 01-1.327 2.371z"
        />
        <path
          style={{ fill: '#023577' }}
          d="M219.152 371.616a1.558 1.558 0 01-1.555-1.478l-.551-10.715a1.559 1.559 0 013.113-.16l.551 10.715a1.56 1.56 0 01-1.476 1.637z"
        />
      </g>
    </g>
  </svg>
);

export const ArtGuy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={438.6}
    height={346.372}
    viewBox="0 0 438.6 346.372"
  >
    <defs />
    <g transform="translate(92.352 118.197)">
      <path
        d="M93.6 95.171h54.2c3.154 0 4.3.7 4.433.89a6.577 6.577 0 010 3.658l-22.747 47.621h-8.335c-6.217-7.816-22.862-29.385-28.255-42.871-2.13-5.326-1.343-7.439-1.052-7.959a3.584 3.584 0 011.755-1.339m-1.031-8.106s-14.406 2.4-7.2 20.416 31.9 47.963 31.9 47.963h17.321l25.209-52.765s5.4-15.61-12.006-15.61z"
        fill="#172d55"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M200.457 146.845a10.208 10.208 0 007.152 12.536 10.208 10.208 0 0012.537-7.154l26.277-96.122a10.208 10.208 0 00-7.151-12.534 10.207 10.207 0 00-12.537 7.152z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M262.875 90.669l23.412-72.635S294.087.023 272.481.023h-71.436s-12.6-1.8-21.012 26.413-16.21 66.634-16.21 66.634-1.2 15.007 13.207 15.007h64.833a26.074 26.074 0 0021.012-17.408zM90.775 127.286h132.667a10.81 10.81 0 0110.81 10.81 10.811 10.811 0 01-10.81 10.81H90.775a10.811 10.811 0 01-10.81-10.81 10.809 10.809 0 0110.81-10.81z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M101.473 188.557a9.1 9.1 0 014.128.989h45.972a9.05 9.05 0 011.116-.472v-16.58a7.923 7.923 0 01-6.025-7.643v-9.92h-46.441a4.565 4.565 0 01-4.521-5.443h119.164a4.569 4.569 0 01-3.612 5.358 4.539 4.539 0 01-.91.084h-47.616v9.92a7.872 7.872 0 01-4.017 6.834v17.389a8.937 8.937 0 011.115.471h44.967a9.106 9.106 0 014.127-.991 8.893 8.893 0 11-9.038 8.891 8.759 8.759 0 01.525-2.963h-36.2a8.694 8.694 0 01.525 2.963 9.038 9.038 0 01-18.075 0 8.76 8.76 0 01.525-2.963h-37.2a8.758 8.758 0 01.524 2.963 9.038 9.038 0 11-9.186-8.89h.16z"
      />
      <path
        style={{ fill: '#223a74' }}
        d="M174.038 95.169h54.2c3.154 0 4.3.7 4.432.89a6.6 6.6 0 010 3.657l-22.748 47.619h-8.335c-6.219-7.818-22.864-29.385-28.256-42.872-2.13-5.325-1.342-7.437-1.049-7.959a3.579 3.579 0 011.754-1.338m-1.026-8.1s-14.406 2.4-7.2 20.413 31.9 47.964 31.9 47.964h17.321l25.208-52.767s5.4-15.608-12.005-15.608z"
      />
      <g>
        <g transform="translate(0 84.965)">
          <rect
            style={{ fill: '#1b5faa' }}
            width={346.249}
            height={10.596}
            rx={3.123}
          />
          <path
            style={{ fill: '#1b5faa' }}
            d="M9.165 7.633h12.159v135.578H9.165zM295.641 7.633H307.8v135.578h-12.159z"
          />
          <path
            style={{ fill: '#1e4382' }}
            d="M33.481 10.596H45.64v132.615H33.481zM319.958 10.596h12.159v132.615h-12.159z"
          />
        </g>
        <path
          style={{ fill: '#f36e2c' }}
          d="M182.965 85.433l.073-29.828 22.597.056-.073 29.828z"
        />
        <path
          style={{ fill: '#f36e2c' }}
          d="M189.501 61.627a7.712 7.712 0 017.685 7.722l-.008 3.013a7.714 7.714 0 01-7.723 7.685l-5.424-.013a7.714 7.714 0 01-7.685-7.723l.008-3.013a7.715 7.715 0 017.724-7.685l5.424.014m.008-3.143l-5.423-.013a10.845 10.845 0 00-10.874 10.818l-.008 3.013a10.846 10.846 0 0010.82 10.874l5.424.014a10.848 10.848 0 0010.874-10.82l.009-3.013a10.846 10.846 0 00-10.819-10.872z"
        />
        <path
          style={{ fill: '#3a6fb6' }}
          d="M65.755 78.991h86.05a3.144 3.144 0 013.145 3.144 3.145 3.145 0 01-3.145 3.145h-86.05a3.144 3.144 0 01-3.144-3.144 3.144 3.144 0 013.144-3.145z"
        />
        <path
          style={{ fill: '#223a74' }}
          d="M44.831 78.87c0 3.328 2.162 6.028 4.815 6.028h69.409c2.659 0 4.816-2.7 4.816-6.028V28.655c0-3.328-2.162-6.026-4.816-6.026H49.646c-2.66 0-4.815 2.7-4.815 6.026z"
        />
      </g>
      <ellipse
        style={{ fill: '#1b5faa' }}
        cx={5.746}
        cy={6.224}
        rx={5.746}
        ry={6.224}
        transform="rotate(-34 125.188 -97.83)"
      />
    </g>
    <path
      d="M27.969 340.301h37.838a2.811 2.811 0 002.811-2.811v-11.669a2.811 2.811 0 00-2.811-2.811H46.715a2.807 2.807 0 00-1.484.425l-18.747 11.669a2.811 2.811 0 001.485 5.2z"
      fill="#a9a5a4"
    />
    <rect
      style={{ fill: '#313a3f' }}
      width={71.956}
      height={24.447}
      rx={11.567}
      transform="rotate(-71.71 188.517 98.608)"
    />
    <path
      style={{ fill: '#313a3f' }}
      d="M60.19 246.169l9.185 77.319a3.129 3.129 0 01-2.8 3.432l-17.372-1.087a3.131 3.131 0 01-3.432-2.8l-7.6-74.626a11.063 11.063 0 019.885-12.128 11.065 11.065 0 0112.134 9.89zM106.178 195.022l-5.21 56.922c-.636 6.954-6.078 11.99-12.159 11.25l-2.293-.279c-6.077-.74-10.488-6.977-9.853-13.931l5.21-56.922c.636-6.954 6.078-11.99 12.159-11.25l2.293.278c6.078.744 10.49 6.982 9.853 13.932z"
    />
    <path
      d="M64.761 346.358H102.6a2.81 2.81 0 002.81-2.811v-11.669a2.81 2.81 0 00-2.81-2.81H83.505a2.812 2.812 0 00-1.485.424l-18.746 11.67a2.811 2.811 0 001.485 5.2z"
      fill="#d0cccb"
    />
    <path
      style={{ fill: '#313a3f' }}
      d="M99.959 252.881l5.549 77.666a3.132 3.132 0 01-2.956 3.3l-17.3-1.9a3.131 3.131 0 01-3.3-2.954l-4.089-74.9a11.062 11.062 0 0110.443-11.65 11.064 11.064 0 0111.653 10.441z"
    />
    <path
      style={{ fill: '#1f2528' }}
      d="M105.52 330.56l-4.887-72.817.97 68.972c-.07 3.721-2.549 5.3-5.586 6.384l6.533.746a3.132 3.132 0 002.97-3.285zM69.17 323.74l-8.571-72.476 4.465 68.833c.119 3.72-2.278 5.423-5.255 6.66l6.563.415a3.13 3.13 0 002.8-3.431z"
    />
    <path
      style={{ fill: '#f3f3f3' }}
      d="M51.629 151.621a8.689 8.689 0 008.7-8.681V86.967a16.121 16.121 0 00-16.135 16.108l-1.254 39.593a8.688 8.688 0 008.417 8.952c.088-.001.18.001.272.001z"
    />
    <path
      style={{ fill: '#e5e4e4' }}
      d="M55.8 154.598a7.747 7.747 0 00-1.234-10.886 7.709 7.709 0 00-.248-.189l-13.532-9.838a7.187 7.187 0 01-8.943 10.52l11.75 11.417a8.407 8.407 0 00.844.712 8.3 8.3 0 0011.363-1.736z"
    />
    <rect
      style={{ fill: '#f3f3f3' }}
      width={15.195}
      height={13.499}
      rx={6.387}
      transform="rotate(-49.38 168.616 40.184)"
    />
    <path
      style={{ fill: '#cb8b70' }}
      d="M36.7 143.555a7.174 7.174 0 004-4.9 4.07 4.07 0 00-1.551-4.164l-19.75-14.786a3.691 3.691 0 00-5.011.545 3.76 3.76 0 00.269 5.1l17.06 17.536a4.1 4.1 0 004.093 1.017 7.134 7.134 0 00.89-.348z"
    />
    <path
      style={{ fill: '#3d3937' }}
      d="M58.978 18.319c5.274 0 9.55 5.187 9.55 11.586s-5.656 20.063-10.93 20.063-6.973-10.376-6.973-16.775 3.078-14.874 8.353-14.874z"
    />
    <rect
      style={{ fill: '#313a3f' }}
      width={51.833}
      height={33.654}
      rx={15.924}
      transform="translate(55.165 168.227)"
    />
    <path
      style={{ fill: '#1f2528' }}
      d="M56.561 178.332l50.417 6.171a16.823 16.823 0 00-16.813-16.272H71.987a16.826 16.826 0 00-15.426 10.101z"
    />
    <path
      style={{ fill: '#f3f3f3' }}
      d="M101.428 174.81H58.474c-3.08 0-5.584-2.169-5.61-4.858l-.751-77.922a5 5 0 013.855-4.694l16.593-4.765a6.4 6.4 0 012.918-.139l27.114 5.02a5.109 5.109 0 014.447 4.793v77.665c-.002 2.706-2.515 4.9-5.612 4.9z"
    />
    <rect
      style={{ fill: '#cb8b70' }}
      width={14.577}
      height={22.015}
      rx={6.897}
      transform="translate(69.1 68.742)"
    />
    <path
      style={{ fill: '#b77a65' }}
      d="M72.955 84.192a11.657 11.657 0 0010.723-6.519v-.262c.405-9.478-3.264-7.289-7.289-7.289a7.288 7.288 0 00-7.289 7.289v6.171a12.6 12.6 0 003.855.61z"
    />
    <path
      style={{ fill: '#cb8b70' }}
      d="M104.599 39.316c-.672 15.739-15.017 38.9-32.7 38.85-17.782 0-20.729-14.327-18.8-27.311.109-25.746 1.908-38.5 26.485-39.986 14.524-.882 25.759 11.019 25.015 28.447z"
    />
    <path
      style={{ fill: '#9e6757' }}
      d="M70.458 65.44a13.929 13.929 0 01-7.218-2 .374.374 0 11.387-.64 13.288 13.288 0 0015.006-.912.374.374 0 01.462.588 13.992 13.992 0 01-8.637 2.964z"
    />
    <path
      style={{ fill: '#9e6757' }}
      d="M80.314 64.174a.372.372 0 01-.371-.34 2.494 2.494 0 00-.1-.5 2.476 2.476 0 00-3.088-1.647.373.373 0 11-.218-.714 3.221 3.221 0 014.147 2.8.374.374 0 01-.34.4z"
    />
    <path
      style={{ fill: '#b77a65' }}
      d="M83.263 26.117a141.653 141.653 0 0014.026-8.458 22.248 22.248 0 00-17.713-6.794c-16.27.986-21.968 8.357-24.481 16.079a16.114 16.114 0 006.9.733c3.366-.312 18.013-.5 21.268-1.56z"
    />
    <circle
      style={{ fill: '#342b26' }}
      cx={1.673}
      cy={1.673}
      r={1.673}
      transform="translate(60.74 43.497)"
    />
    <path
      style={{ fill: '#3d3937' }}
      d="M57.549 39.382l.291.818a1.075 1.075 0 001.336.671 23.71 23.71 0 016.054-1.059 1.079 1.079 0 001.015-1.1l-.014-.928a1.089 1.089 0 00-1.111-1.068h-.049a26.972 26.972 0 00-6.816 1.267 1.091 1.091 0 00-.714 1.367zM74.663 36.808l.015.863a1.074 1.074 0 001.052 1.062 23.752 23.752 0 016.075.931 1.078 1.078 0 001.313-.715l.287-.886a1.09 1.09 0 00-.715-1.366l-.044-.013a26.991 26.991 0 00-6.863-.978 1.091 1.091 0 00-1.118 1.062z"
    />
    <circle
      style={{ fill: '#342b26' }}
      cx={1.673}
      cy={1.673}
      r={1.673}
      transform="translate(77.536 43.497)"
    />
    <path
      style={{ fill: '#f3f3f3' }}
      d="M108.519 152.321a8.69 8.69 0 01-8.7-8.681V87.664a16.123 16.123 0 0116.136 16.108l1.254 39.594a8.689 8.689 0 01-8.418 8.951c-.088.002-.181.004-.272.004z"
    />
    <path
      style={{ fill: '#3d3937' }}
      d="M101.612 17.839c.05.932-17.378 7.833-18.35 8.074-3.256.809-17.9-.126-21.271-.072a14.7 14.7 0 01-9.421-2.777c-2.974-2.4-4.232-6.149-4.754-9.772a4.361 4.361 0 01.461-3.3c.7-.933 2.481-1.161 3.059-.162-.642-2.295 1.307-4.7 3.726-5.46a11.923 11.923 0 017.48.61 74.173 74.173 0 007.109 2.643c5.081 1.258 10.512-.006 15.714.718 7.847 1.096 11.596 2.115 16.247 9.498zM95.617 50.924a3.072 3.072 0 002.11 2.983 8.954 8.954 0 001.735.453c.3.015.8.338 1.083.244a39.278 39.278 0 004.053-15.288 35.423 35.423 0 00-.443-7.424c-.862-1.206-2.275-1.711-4.472-1.068-2.76.81-3.421 4.2-3.775 6.66z"
    />
    <rect
      style={{ fill: '#cb8b70' }}
      width={11.448}
      height={7.339}
      rx={3.408}
      transform="rotate(-68.787 89.455 -41.057)"
    />
    <path
      style={{ fill: '#3d3937' }}
      d="M95.362 37.045a5.575 5.575 0 002.767 1.357 7.207 7.207 0 003.388.034c2.008-.632 1.273 9.911 2.03 7.947.732-1.947 3.394-16.083 3.272-18.159a14.636 14.636 0 00-1.653-7.25 6.783 6.783 0 00-6.177-3.544 1.508 1.508 0 00-1.352.783c-.108.3-6.92 2.321-7.887 3.1-1.543 1.233-2.922 3.181-2.632 5.269.366 2.652 2.485 2.719 4.3 4.032 2.295 1.651 2.051 4.545 3.944 6.431z"
    />
    <path
      d="M61.991 23.492a14.693 14.693 0 01-9.421-2.777c-2.965-2.389-4.225-6.127-4.75-9.739a6.092 6.092 0 000 2.316c.522 3.623 1.78 7.376 4.754 9.772a14.7 14.7 0 009.421 2.777c3.188-.053 16.463.782 20.642.187-5.541-.196-17.768-2.583-20.646-2.536z"
      fill="#2e2b28"
    />
    <path
      style={{ fill: '#e5e4e4' }}
      d="M102.158 144.027a.385.385 0 01-.385-.385v-32.428a.386.386 0 11.771 0v32.431a.385.385 0 01-.386.382zM52.706 144.027a.385.385 0 01-.385-.385v-32.428a.386.386 0 01.771 0v32.431a.385.385 0 01-.386.382zM111.141 141.461a7.747 7.747 0 00-9.036 6.193c-.019.1-.036.2-.051.308l-2.347 16.565a7.348 7.348 0 01.6-.925 7.187 7.187 0 0112.884 3.893l4.577-15.731a8.534 8.534 0 00.233-1.078 8.3 8.3 0 00-6.86-9.225z"
    />
    <rect
      style={{ fill: '#f3f3f3' }}
      width={13.5}
      height={15.196}
      rx={6.387}
      transform="rotate(-77.362 154.13 22.901)"
    />
    <path
      style={{ fill: '#cb8b70' }}
      d="M110.346 163.508a7.175 7.175 0 00-6.207-1.233 4.071 4.071 0 00-2.952 3.321l-3.8 24.378a3.691 3.691 0 002.831 4.17 3.758 3.758 0 004.378-2.628l7.49-23.287a4.1 4.1 0 00-1.021-4.094 7.389 7.389 0 00-.719-.627zM92.593 197.326c.892.864-.038 3.269-2.076 5.371s-4.6 3.269-5.3 2.243c-1.237-1.817 1.141-2.143 3.178-4.245s3.306-4.231 4.198-3.369z"
    />
    <path
      style={{ fill: '#cb8b70' }}
      d="M102.73 189.33c2.629 1.715 3.829 6.483-2.331 12.761-4.492 4.577-7.72 8.408-10.351 6.693s-2.363-6.752 1.374-11.965c3.828-5.337 8.677-9.201 11.308-7.489z"
    />
    <path
      style={{ fill: '#cb8b70' }}
      d="M89.719 199.621a16.623 16.623 0 00-1.937 6.486c-.179 4.365 1.591 8.992 4.778 7.418a2.036 2.036 0 003.274-.4c1.251.424 2.41-.251 3.2-1.909 1.586.487 2.567-1.907 1.257-3.1-1.518-1.383-.308-5.506 1.82-8.083 3.108-3.749-12.392-.412-12.392-.412z"
    />
    <g>
      <path
        style={{ fill: '#cb8b70' }}
        d="M11.759 118.23c1.662-1.36 1.91-2.767.315-3.919.144-.44 1.543-3.603 1.56-3.977.054.01.629 3.174.684 3.18a5.978 5.978 0 00.543 2.097c.805 1.768 2.242 2.834 3.192 2.4.418-.193.44-.54.262-1.02A12.408 12.408 0 0119.18 119c.773 2.406.516 4.617-1.303 5.71a10.638 10.638 0 01-6.837.752 9.025 9.025 0 01-2.823-1.058z"
      />
      <path
        style={{ fill: '#cb8b70' }}
        d="M18.056 118.008c-.953.44-2.389-.633-3.195-2.393a5.978 5.978 0 01-.543-2.102 2.592 2.592 0 001.439-.279 1 1 0 00.638-1.208 24.57 24.57 0 00.697 2.532c.451.994 1 1.819 1.226 2.433.176.48.155.829-.262 1.017z"
      />
      <path
        style={{ fill: '#cb8b70' }}
        d="M15.657 113.429a2.594 2.594 0 01-1.46.11c-.054-.009-.11-.019-.164-.037a2.739 2.739 0 00-1.337-2.835 5.827 5.827 0 00-2.08-.844c-.66-2.469-.055-5.003 1.608-5.215s1.699 1.574 2.647 4.01c.602 1.55 1.462 2.797 1.558 3.68a1 1 0 01-.772 1.13z"
      />
      <path
        style={{ fill: '#cb8b70' }}
        d="M13.505 114.401c1.637 1.182 1.55 2.866-.16 4.264a2.54 2.54 0 01.274.86c.156 1.26-.76 2.507-2.674 3.527.384.812-.145 1.168-1.083 1.295a29.648 29.648 0 01-5.016-.22 1.537 1.537 0 01-.502-.129c-3.255-1.414-4.534-13.842.55-14.326a18.953 18.953 0 015.261.116 6.317 6.317 0 012.296.86 2.957 2.957 0 011.054 3.753z"
      />
      <ellipse
        style={{ fill: '#b87356' }}
        cx={2.429}
        cy={2.395}
        rx={2.429}
        ry={2.395}
        transform="rotate(10.02 -620.294 108.274)"
      />
      <ellipse
        style={{ fill: '#b87356' }}
        cx={2.429}
        cy={2.395}
        rx={2.429}
        ry={2.395}
        transform="rotate(10.02 -638.008 115.643)"
      />
      <ellipse
        style={{ fill: '#b87356' }}
        cx={2.429}
        cy={2.749}
        rx={2.429}
        ry={2.749}
        transform="rotate(10.02 -656.115 114.252)"
      />
      <ellipse
        style={{ fill: '#b87356' }}
        cx={2.429}
        cy={1.889}
        rx={2.429}
        ry={1.889}
        transform="rotate(10.02 -681.045 106.634)"
      />
      <g>
        <ellipse
          style={{ fill: '#cb8b70' }}
          cx={2.429}
          cy={2.395}
          rx={2.429}
          ry={2.395}
          transform="rotate(10.02 -619.421 104.212)"
        />
        <ellipse
          style={{ fill: '#cb8b70' }}
          cx={2.429}
          cy={2.395}
          rx={2.429}
          ry={2.395}
          transform="rotate(10.02 -637.134 111.581)"
        />
        <ellipse
          style={{ fill: '#cb8b70' }}
          cx={2.429}
          cy={2.749}
          rx={2.429}
          ry={2.749}
          transform="rotate(10.02 -655.241 110.19)"
        />
        <ellipse
          style={{ fill: '#cb8b70' }}
          cx={2.429}
          cy={1.889}
          rx={2.429}
          ry={1.889}
          transform="rotate(10.02 -680.172 102.572)"
        />
      </g>
    </g>
    <g>
      <path
        style={{ fill: '#9e6757' }}
        d="M66.619 55.187a3.22 3.22 0 01-1.923-.849 2.775 2.775 0 01-.784-1.582 1.5 1.5 0 01.38-1.211c.217-.325 1.054-.829 1.474-1.465 1.481-2.245 2.12-4.175 2.892-5.329a.486.486 0 11.807.541c-1.355 2.023-2.087 4.109-2.888 5.322-.421.639-1.259 1.143-1.491 1.492-.218.292-.21.239-.206.577a1.8 1.8 0 00.511.975c.618.648 2.436.935 5.136.135a.486.486 0 11.276.931 10.23 10.23 0 01-4.184.463z"
      />
    </g>
    <g transform="translate(-1000.526 -340.341)">
      <path
        style={{ fill: '#1e4382' }}
        d="M1203.941 340.341h119v76.706h-119z"
      />
      <path
        style={{ fill: '#3a6fb6' }}
        d="M1209.952 346.962h105.914v62.612h-105.914z"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2159a8',
          strokeMiterlimit: 10,
          strokeWidth: '17px',
        }}
        d="M1209.952 369.784h25.484v-22.822"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#2159a8',
          strokeMiterlimit: 10,
          strokeWidth: '17px',
        }}
        d="M1209.952 394.946h42.717v-47.984"
      />
      <circle
        style={{ fill: '#1e4382' }}
        cx={14.922}
        cy={14.922}
        r={14.922}
        transform="translate(1273.149 351.644)"
      />
      <path d="M1288.071 381.486l-27.186 28.088h54.372z" fill="#2159a8" />
    </g>
  </svg>
);

/// /////////
// Wave
/// /////////

export const HeroWaves = ({ color = colors.blueMedium }) => (
  <svg x={0} y={0} viewBox="0 0 2995 32.3" xmlSpace="preserve">
    <path
      style={{ fill: 'none' }}
      d="M3003.8 0h-8.6c5.4 15.1 8.5 23.5 8.6 23.8V0z"
    />
    <path
      style={{ fill: 'none' }}
      d="M2040.5 15.6c-384.2 23.4-496.9 21.2-853.5 0s-485 0-485 0S419.7 48.2 212.6 0H0v32.3h3003.8v-8.6c-.5.1-579.2-31.4-963.3-8.1z"
    />
    <path
      d="M702 15.6s128.4-21.2 485 0 469.2 23.4 853.5 0c384.1-23.3 962.8 8.1 963.3 8.1-.1-.2-3.1-8.7-8.6-23.8H212.6C419.7 48.2 702 15.6 702 15.6z"
      style={{ fill: color }}
    />
  </svg>
);

/// /////////
// Icons
/// /////////

export const Arrow = ({ color = colors.white, bgColor = colors.blueDark }) => (
  <svg width={35} height={35} viewBox="0 0 35 35">
    <g transform="translate(-7230 -859)">
      <circle
        cx={17.5}
        cy={17.5}
        r={17.5}
        transform="translate(7230 859)"
        style={{ fill: bgColor }}
      />
      <path
        d="M7245.236 870.958l5 4.926-5 5"
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeWidth: 3,
        }}
      />
    </g>
  </svg>
);

export const ArrowDown = ({
  color = colors.white,
  circleColor = colors.blue,
}) => (
  <svg width={50.219} height={50.219} viewBox="0 0 50.219 50.219">
    <defs>
      <clipPath id="SetaClip">
        <path style={{ fill: 'none' }} d="M0 0h13.746v6.873H0z" />
      </clipPath>
      <filter
        id="SetaFilter"
        x={0}
        y={0}
        width={50.219}
        height={50.219}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="b" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="b" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#SetaFilter)">
      <circle
        cx={16.11}
        cy={16.11}
        r={16.11}
        transform="translate(9 6)"
        style={{ fill: circleColor }}
      />
    </g>
    <g transform="translate(18.205 19.808)" clipPath="url(#SetaClip)">
      <path d="M13.746 0L6.872 6.873 0 0z" style={{ fill: color }} />
    </g>
  </svg>
);

export const Info = () => (
  <svg width={15.21} height={15.21} viewBox="0 0 15.21 15.21">
    <defs>
      <clipPath id="InfoClipPath">
        <path fill="none" d="M0 0h15.21v15.21H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#InfoClipPath)">
      <path
        d="M7.54 0a7.54 7.54 0 107.54 7.54A7.549 7.549 0 007.54 0z"
        style={{ fill: '#2e578b' }}
      />
      <path
        style={{ fill: '#fff' }}
        d="M7.694 3.152a1.107 1.107 0 11-1.107 1.107 1.107 1.107 0 011.107-1.107zM9.213 11.869H6.187v-1.375h.481v-3.37h-.481V5.886h2.682v4.608h.344z"
      />
    </g>
  </svg>
);

export const Check = ({ color = colors.white }) => (
  <svg width={15.773} height={15.773} viewBox="0 0 15.773 15.773">
    <defs>
      <clipPath id="CheckClip">
        <path fill="none" d="M0 0h15.773v15.773H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#CheckClip)">
      <path
        style={{ fill: color }}
        d="M1.9 8.267l5.511 5.511-1.9 1.9L0 10.169z"
      />
      <path
        style={{ fill: color }}
        d="M15.773 5.606L5.701 15.678l-1.9-1.9L13.871 3.704z"
      />
    </g>
  </svg>
);

/// /////////
// Planos
/// /////////

export const PlanoP = () => (
  <svg width={43} height={40} viewBox="0 0 43 40">
    <path
      style={{
        fill: 'none',
        stroke: '#2e578b',
        strokeMiterlimit: 10,
        strokeWidth: '2px',
      }}
      d="M1 1h34v36H1zM1 13h34M1 25h34M22 31h8M22 19h8M22 7h8M6 31h4M6 19h4M6 7h4"
    />
    <g stroke="#fff" strokeWidth={0.5} fill="#ff8c4e">
      <path style={{ stroke: 'none' }} d="M23 26h6v14h-6z" />
      <path style={{ fill: 'none' }} d="M23.25 26.25h5.5v13.5h-5.5z" />
    </g>
    <g style={{ stroke: '#fff', strokeWidth: '.5px', fill: '#cbdde9' }}>
      <path style={{ stroke: 'none' }} d="M29 23h7v17h-7z" />
      <path style={{ fill: 'none' }} d="M29.25 23.25h6.5v16.5h-6.5z" />
    </g>
    <g style={{ stroke: '#fff', strokeWidth: '.5px', fill: '#cbdde9' }}>
      <path style={{ stroke: 'none' }} d="M36 19h7v21h-7z" />
      <path style={{ fill: 'none' }} d="M36.25 19.25h6.5v20.5h-6.5z" />
    </g>
  </svg>
);

export const PlanoM = () => (
  <svg width={43} height={40} viewBox="0 0 43 40">
    <path
      style={{
        fill: 'none',
        stroke: '#2e578b',
        strokeMiterlimit: 10,
        strokeWidth: '2px',
      }}
      d="M1 1h34v36H1zM1 13h34M1 25h34M22 31h8M22 19h8M22 7h8M6 31h4M6 19h4M6 7h4"
    />
    <g stroke="#fff" strokeWidth={0.5} fill="#ff8c4e">
      <path style={{ stroke: 'none' }} d="M23 26h6v14h-6z" />
      <path style={{ fill: 'none' }} d="M23.25 26.25h5.5v13.5h-5.5z" />
    </g>
    <g fill="#ff7c3b">
      <path style={{ stroke: 'none' }} d="M35.75 39.75h-6.5v-16.5h6.5v16.5z" />
      <path d="M29.5 23.5v16h6v-16h-6M29 23h7v17h-7V23z" fill="#fff" />
    </g>
    <g fill="#cbdde9" stroke="#fff" strokeWidth={0.5}>
      <path style={{ stroke: 'none' }} d="M36 19h7v21h-7z" />
      <path style={{ fill: 'none' }} d="M36.25 19.25h6.5v20.5h-6.5z" />
    </g>
  </svg>
);

export const PlanoTurbo = () => (
  <svg width={41} height={41} viewBox="0 0 41 41">
    <path
      style={{
        fill: 'none',
        stroke: '#2e578b',
        strokeMiterlimit: 10,
        strokeWidth: '2px',
      }}
      d="M1 1h34v36H1zM1 13h34M1 25h34M22 31h8M22 19h8M22 7h8M6 31h4M6 19h4M6 7h4"
    />
    <g fill="#ff8c4e">
      <path style={{ stroke: 'none' }} d="M26.75 40.75h-5.5v-13.5h5.5v13.5z" />
      <path d="M21.5 27.5v13h5v-13h-5M21 27h6v14h-6V27z" fill="#fff" />
    </g>
    <g stroke="#fff" strokeWidth={0.5} fill="#ff7c3b">
      <path style={{ stroke: 'none' }} d="M27 24h7v17h-7z" />
      <path style={{ fill: 'none' }} d="M27.25 24.25h6.5v16.5h-6.5z" />
    </g>
    <g fill="#ff6a17" stroke="#fff" strokeWidth={0.5}>
      <path style={{ stroke: 'none' }} d="M34 20h7v21h-7z" />
      <path style={{ fill: 'none' }} d="M34.25 20.25h6.5v20.5h-6.5z" />
    </g>
  </svg>
);

/// /////////
// Radio Button
/// /////////

export const RadioOn = ({ color = colors.white }) => (
  <svg width={18} height={18} viewBox="0 0 18 18">
    <path
      d="M9 0a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9zm0 16.2A7.2 7.2 0 011.8 9 7.2 7.2 0 019 1.8 7.2 7.2 0 0116.2 9 7.2 7.2 0 019 16.2zm0-2.7A4.5 4.5 0 0013.5 9 4.5 4.5 0 009 4.5 4.5 4.5 0 004.5 9 4.5 4.5 0 009 13.5z"
      style={{ fill: color }}
    />
  </svg>
);

export const RadioOff = ({ color = colors.blueGray }) => (
  <svg width={18} height={18} viewBox="0 0 18 18">
    <defs>
      <clipPath id="RadioOffClip">
        <path fill="none" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#RadioOffClip)">
      <path
        d="M9 0a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9zm0 16.2A7.2 7.2 0 011.8 9 7.2 7.2 0 019 1.8 7.2 7.2 0 0116.2 9 7.2 7.2 0 019 16.2z"
        style={{ fill: color }}
      />
    </g>
  </svg>
);
